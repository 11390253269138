export function scrollToElement(elementId) {
  const element = document.getElementById(elementId);
  if (element) {
    element.scrollIntoView({ behavior: "smooth" });
  } else {
    console.error(`Element with id '${elementId}' not found.`);
  }
}
export const ImageConfig = {
  logo: "https://static.tianting.tech/assets/images/ttLogo.png",
  backGround: "https://static.tianting.tech/assets/images/bg-presentation.jpg",
  bgBack: "https://static.tianting.tech/assets/images/rotating-card-bg-back.jpeg",
  bgFront: "https://static.tianting.tech/assets/images/rotating-card-bg-front.jpeg",
};
