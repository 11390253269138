const imagesPrefix = "https://static.tianting.tech/assets/pics";

export default [
  {
    title: "精美的画面设计",
    description:
      "结合AI技术，我们的开发工具套件不仅易用，还能通过智能化的编码辅助和自动化的错误检测，大幅提升开发效率。",
    items: [
      {
        image: `${imagesPrefix}/headers.jpg`,
        name: "文化旅行相关",
        count: 10,
        route: "/sections/page-sections/page-headers",
      },
      {
        image: `${imagesPrefix}/features.jpg`,
        name: "创意",
        count: 14,
        route: "/sections/page-sections/features",
      },
      {
        image: `${imagesPrefix}/pricing.jpg`,
        name: "会员系统模式",
        count: 8,
        route: "/sections/page-sections/pricing",
      },
      {
        image: `${imagesPrefix}/faq.jpg`,
        name: "FAQ",
        count: 1,
        route: "/sections/page-sections/faq",
      },
      {
        image: `${imagesPrefix}/blogs.jpg`,
        name: "在线社区",
        count: 11,
        route: "/sections/page-sections/blog-posts",
      },
      {
        image: `${imagesPrefix}/testimonials.jpg`,
        name: "打造KOL个人IP",
        count: 11,
        route: "/sections/page-sections/testimonials",
      },
      {
        image: `${imagesPrefix}/projects.jpg`,
        name: "软件工具开发",
        count: 6,
        route: "/sections/page-sections/applications",
      },
      {
        image: `${imagesPrefix}/content-sections.jpg`,
        name: "完美的内容",
        count: 8,
        route: "/sections/page-sections/content-sections",
      },
    ],
  },
];
