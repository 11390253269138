// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import GitHubIcon from "@mui/icons-material/GitHub";
import YouTubeIcon from "@mui/icons-material/YouTube";

// Material Kit 2 PRO React components
import MKTypography from "components/MKTypography";

// Images
import ttLogo from "../src/assets/images/ttLogo.png";

export default {
  brand: {
    name: "成都天庭科技有限公司",
    image: ttLogo,
    route: "/",
    address: "中国（四川）自由贸易试验区成都高新区天府大道中段500号1栋36楼3622号",
    email: "service@tianting.tech",
  },
  socials: [
    {
      icon: <FacebookIcon />,
      link: "https://www.facebook.com/CreativeTim/",
    },
    {
      icon: <TwitterIcon />,
      link: "https://twitter.com/creativetim",
    },
    {
      icon: <GitHubIcon />,
      link: "https://github.com/creativetimofficial",
    },
    {
      icon: <YouTubeIcon />,
      link: "https://www.youtube.com/channel/UCVyTG4sCw-rOvB9oHkzZD1w",
    },
  ],
  menus: [
    {
      name: "关于我们",
      items: [
        {
          name: "产品介绍",
          href: "https://www.creative-tim.com/presentation",
          nodeId: "productDiv",
        },
      ],
    },
    {
      name: "新闻动态",
      items: [
        { name: "公司新闻", href: "https://www.creative-tim.com/presentation", nodeId: "newsDiv" },
        {
          name: "产品更新",
          href: "https://www.creative-tim.com/templates/free",
          nodeId: "newsDiv",
        },
      ],
    },
    {
      name: "相关案例",
      items: [
        {
          name: "解决方案支持",
          href: "https://www.creative-tim.com/presentation",
          nodeId: "userDiv",
        },
      ],
    },
  ],
  copyright: (
    <MKTypography variant="button" fontWeight="regular">
      © 2019-2024 成都天庭科技有限公司 &nbsp;&nbsp;
      <a href={"https://beian.miit.gov.cn/"} style={{ color: "#000" }}>
        蜀ICP备2024052387号
      </a>
    </MKTypography>
  ),
};
