/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import { scrollToElement } from "../../../utils/utils";

function DefaultFooter({ content }) {
  const { brand, menus, copyright } = content;
  // 发起邮件
  const toConact = () => {
    var mailtoLink = "mailto:service@tianting.tech";
    window.open(mailtoLink, "_blank");
  };
  return (
    <MKBox component="footer">
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} sx={{ ml: "auto", mb: 3 }}>
            <MKBox>
              <MKBox component="img" src={brand.image} alt={brand.name} maxWidth="2rem" mb={2} />
              <MKTypography variant="h4">{brand.name}</MKTypography>
              <MKTypography mb={2} variant="h6">
                地址：{brand.address}
              </MKTypography>
              <div onClick={toConact} style={{ cursor: "pointer" }}>
                <MKTypography fontWeight="bold" variant="button">
                  联系我们：service@tianting.tech
                </MKTypography>
              </div>
            </MKBox>
            {/* <MKBox display="flex" alignItems="center" mt={3}>*/}
            {/*  {socials.map(({ icon, link }, key) => (*/}
            {/*    <MKTypography*/}
            {/*      key={link}*/}
            {/*      component="a"*/}
            {/*      href={link}*/}
            {/*      target="_blank"*/}
            {/*      rel="noreferrer"*/}
            {/*      variant="h5"*/}
            {/*      color="dark"*/}
            {/*      opacity={0.8}*/}
            {/*      mr={key === socials.length - 1 ? 0 : 2.5}*/}
            {/*    >*/}
            {/*      {icon}*/}
            {/*    </MKTypography>*/}
            {/*  ))}*/}
            {/* </MKBox>*/}
          </Grid>
          {menus.map(({ name: title, items }) => (
            <Grid key={title} item xs={6} md={2} sx={{ mb: 3 }}>
              <MKTypography
                display="block"
                variant="button"
                fontWeight="bold"
                textTransform="capitalize"
                mb={1}
              >
                {title}
              </MKTypography>
              <MKBox component="ul" p={0} m={0} sx={{ listStyle: "none" }}>
                {items.map(({ name, route, href, nodeId }) => (
                  <MKBox key={name} component="li" p={0} m={0} lineHeight={1.25}>
                    {href ? (
                      <div style={{ cursor: "pointer" }} onClick={() => scrollToElement(nodeId)}>
                        <MKTypography
                          component="a"
                          target="_blank"
                          rel="noreferrer"
                          variant="button"
                          fontWeight="regular"
                          textTransform="capitalize"
                        >
                          {name}
                        </MKTypography>
                      </div>
                    ) : (
                      <MKTypography
                        component={Link}
                        to={route}
                        variant="button"
                        fontWeight="regular"
                        textTransform="capitalize"
                      >
                        {name}
                      </MKTypography>
                    )}
                  </MKBox>
                ))}
              </MKBox>
            </Grid>
          ))}
          <Grid item xs={12} sx={{ textAlign: "center", my: 3 }}>
            {copyright}
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

// Typechecking props for the DefaultFooter
DefaultFooter.propTypes = {
  content: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.object, PropTypes.array])).isRequired,
};

export default DefaultFooter;
