/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";

// Material Kit 2 PRO React examples
import RotatingCard from "examples/Cards/RotatingCard";
import RotatingCardFront from "examples/Cards/RotatingCard/RotatingCardFront";
import RotatingCardBack from "examples/Cards/RotatingCard/RotatingCardBack";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

import { ImageConfig } from "../../../utils/utils";
// Image
const bgFront = ImageConfig.bgFront;
const bgBack = ImageConfig.bgBack;

function Information() {
  return (
    <div id={"productDiv"}>
      <MKBox component="section" py={6} my={6}>
        <Container>
          <Grid container item xs={11} spacing={3} alignItems="center" sx={{ mx: "auto" }}>
            <Grid item xs={12} lg={4} sx={{ mx: "auto" }}>
              <RotatingCard>
                <RotatingCardFront
                  image={bgFront}
                  icon="touch_app"
                  title={
                    <>
                      产品
                      <br />
                      和服务
                    </>
                  }
                  description="天庭科技：您的一站式技术解决方案"
                />
                <RotatingCardBack
                  image={bgBack}
                  title="有想法？"
                  description="在这个数字化迅速发展的时代，云端未来 致力于为您提供前沿的云计算平台、先进的软件开发工具和深入的数据分析服务。我们的目标是通过我们的产品和服务，使您的企业能够高效、灵活地应对各种技术挑战。"
                />
              </RotatingCard>
            </Grid>
            <Grid item xs={12} lg={7} sx={{ ml: "auto" }}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <DefaultInfoCard
                    icon="content_copy"
                    title="云计算平台"
                    description="我们的云平台设计以用户友好为核心，确保即使是非技术人员也能轻松上手。我们的解决方案以其高效率而闻名，在保证数据安全的同时，提供快速、灵活的资源管理，确保您的业务能够迅速适应市场变化。"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <DefaultInfoCard
                    icon="flip_to_front"
                    title="软件开发工具"
                    description="面向未来的软件开发需要强大的工具，而我们正是提供这样的工具。我们的开发套件不仅易于使用，还能通过可定制性满足您的特定需求。无论是快速原型设计还是复杂系统开发，我们的工具都能帮助您提高生产力。"
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3} sx={{ mt: { xs: 0, md: 6 } }}>
                <Grid item xs={12} md={6}>
                  <DefaultInfoCard
                    icon="price_change"
                    title="数据分析服务"
                    description="在数据驱动的商业世界中，准确的分析是成功的关键。我们的数据分析服务提供深入的洞察，帮助您从大数据中提取有价值的信息。我们的专家团队结合最新的分析技术，确保您能够做出更明智的业务决策。"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <DefaultInfoCard
                    icon="devices"
                    title="携手AI"
                    description="我们相信AI是推动业务发展的关键驱动力。无论是通过自动化工作流程，还是通过深度学习和预测分析，我们的AI解决方案都旨在提升业务效率，为您的企业带来可量化的价值和竞争优势。"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
    </div>
  );
}

export default Information;
