/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import DefaultReviewCard from "examples/Cards/ReviewCards/DefaultReviewCard";

// Images
// import appleLogo from "assets/images/logos/gray-logos/logo-apple.svg";
// import facebookLogo from "assets/images/logos/gray-logos/logo-facebook.svg";
// import nasaLogo from "assets/images/logos/gray-logos/logo-nasa.svg";
// import vodafoneLogo from "assets/images/logos/gray-logos/logo-vodafone.svg";
// import digitalOceanLogo from "assets/images/logos/gray-logos/logo-digitalocean.svg";

function Information() {
  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={6}
          justifyContent="center"
          sx={{ mx: "auto", textAlign: "center" }}
        >
          <MKTypography variant="h2" mb={2}>
            关于我们
          </MKTypography>
          <MKTypography variant="h2" color="info" textGradient mb={2}></MKTypography>
          <MKTypography variant="body1" color="text" mb={2}>
            成都天庭科技有限公司是一家高科技企业，坐落于中国创新中心的核心区域。自 2019
            年以来，我们专注于云计算、软件开发、数据处理和互联网技术的开发，致力于通过尖端技术推动商业和社会进步。
          </MKTypography>
        </Grid>
        <Grid container spacing={3} sx={{ mt: 8 }}>
          <Grid item xs={12} md={6} lg={4}>
            <DefaultReviewCard
              name="我们的使命"
              date="从始至终"
              review="我们的使命是利用最先进的科技解决方案，为客户提供卓越的服务，帮助他们在数字化转型的道路上取得成功。我们相信，通过创新和卓越的技术，我们能够为更美好的明天铺平道路。"
              rating={5}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <DefaultReviewCard
              color="info"
              name="我们的承诺"
              date="1 week ago"
              review="成都天庭科技有限公司承诺提供高质量、可靠和创新的技术解决方案。我们与客户紧密合作，确保他们的需求得到满足，并不断追求卓越和创新。"
              rating={5}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <DefaultReviewCard
              name="我们的业务"
              date="3 weeks ago"
              review="我们的软件开发团队拥有丰富的行业经验，专注于创造用户友好、功能强大的应用程序。提供全面的云服务，包括云存储、云计算能力和定制化云解决方案，以满足不同行业和规模企业的需求。"
              rating={5}
            />
          </Grid>
        </Grid>
        <Divider sx={{ my: 1 }} />
      </Container>
    </MKBox>
  );
}

export default Information;
