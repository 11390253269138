// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKSocialButton from "components/MKSocialButton";

// Material Kit 2 PRO React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";
import FilledInfoCard from "examples/Cards/InfoCards/FilledInfoCard";
import homeBg from "assets/images/home-bg.jpg";
// Presentation page sections
import Counters from "pages/presentation/sections/Counters";
import Information from "pages/presentation/sections/Information";
import DesignBlocks from "pages/presentation/sections/DesignBlocks";
// import AuthPages from "pages/presentation/sections/AuthPages";
// import Pages from "pages/presentation/sections/Pages";
import Testimonials from "pages/presentation/sections/Testimonials";
// import Pricing from "pages/presentation/sections/Pricing";

// Presentation page components
import BuiltByDevelopers from "pages/presentation/components/BuiltByDevelopers";
// Routes
import routes from "routes";
import footerRoutes from "footer.routes";
// import { ImageConfig } from "../../utils/utils";
function Presentation() {
  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: "https://www.creative-tim.com/product/material-kit-pro-react",
          label: "buy now",
          color: "info",
        }}
        sticky
      />
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: `url(${homeBg})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid container item xs={16} lg={9} justifyContent="center" mx="auto">
            <MKTypography
              variant="h1"
              color="white"
              mt={-6}
              mb={1}
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["2xl"],
                },
              })}
            >
              领先的云计算解决方案，助力企业高效转型
            </MKTypography>
            <MKTypography variant="body1" color="white" textAlign="center" px={6} mt={1}>
              创新的软件开发和数据处理技术，打造智能互联网未来
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Counters />
        <Information />
        <DesignBlocks />
        {/* <AuthPages />*/}
        {/*<Pages />*/}
        <div id={"newsDiv"}>
          <Container sx={{ mt: 6 }}>
            <BuiltByDevelopers />
          </Container>
          <Container>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={4}>
                <FilledInfoCard
                  variant="gradient"
                  color="info"
                  icon="flag"
                  title="绝对隐私"
                  description="我们尊重并保护每位用户的隐私。不记录、不共享您的网络活动，为您提供真正的匿名上网环境。"
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <FilledInfoCard
                  color="info"
                  icon="precision_manufacturing"
                  title="跨平台支持"
                  description="无论是iOS、Android、Windows还是Mac，都能完美适配。一键连接，所有设备都能畅享安全上网。"
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <FilledInfoCard
                  color="info"
                  icon="apps"
                  title="即将上线"
                  description="敬请期待！让每次连接都成为安全的享受，即将在各大应用商店上线。加入我们，开启您的安全上网新旅程！"
                />
              </Grid>
            </Grid>
          </Container>
        </div>
        <div id={"aboutUsDiv"}>
          <Testimonials />
          <MKBox pt={1} pb={6}>
            <Container>
              <Grid container spacing={3}>
                <Grid
                  item
                  xs={12}
                  lg={5}
                  ml="auto"
                  sx={{ textAlign: { xs: "center", lg: "left" } }}
                >
                  <MKTypography variant="h4" fontWeight="bold" mb={0.5}>
                    感谢您的支持！
                  </MKTypography>
                  <MKTypography variant="body1" color="text">
                    我们将提供最好的产品和服务，以满足您的需求。您的支持是我们前进的动力！
                  </MKTypography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  lg={5}
                  my={{ xs: 5, lg: "auto" }}
                  mr={{ xs: 0, lg: "auto" }}
                  sx={{ textAlign: { xs: "center", lg: "right" } }}
                >
                  <MKSocialButton
                    component="a"
                    href={`https://twitter.com/intent/tweet?text=强烈推荐!${window.location.href}`}
                    target="_blank"
                    color="twitter"
                    sx={{ mr: 1 }}
                  >
                    <i className="fab fa-twitter" />
                    &nbsp;分享到Tweet
                  </MKSocialButton>
                  <MKSocialButton
                    component="a"
                    href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`}
                    target="_blank"
                    color="facebook"
                    sx={{ mr: 1 }}
                  >
                    <i className="fab fa-facebook" />
                    &nbsp;分享到Facebook
                  </MKSocialButton>
                  <MKSocialButton
                    component="a"
                    href={`https://www.pinterest.com/pin/create/button/?url=${window.location.href}`}
                    target="_blank"
                    color="pinterest"
                  >
                    <i className="fab fa-pinterest" />
                    &nbsp;Pin it
                  </MKSocialButton>
                </Grid>
              </Grid>
            </Container>
          </MKBox>
        </div>
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Presentation;
